@import "../../styles/variable";

.cookiesContainer{
    display: none;
}

@include desktop(){
    .cookiesContainer{
        position: fixed;
        bottom: 0;
        z-index: 10;
        width: 100%;
        background-color: #2B3342cc;
        display: block;
        font-family: 'Mulish';
        
    
        
        .container{
            display: flex;
            align-items: center;
            height: 125px;
            gap: 1em;
    
    
            .content{
                color: $text-white;
                font-size: 1.125rem;
                line-height: 26px;
                a{
                    color:$golden;
                    text-decoration: none;
                }
            }
    
            .btnContainer{
                display: flex;
                gap: 0.7em;
                flex-direction: column;
    
    
                .btn{
                    white-space: nowrap;
                    width: 100%;
                    min-width:100px;
                    padding: 0.4em 0.8em;
                    font-size: 1.125rem;
                    border-radius: 5px;
                    border: none;
                    border:2px solid $border-white;
                    background-color: transparent;
                    cursor: pointer;
                    font-family: 'Mulish';
                }
                .btn-background{
                    color:$text-primary;
                    background-color: $bg-white;
                }
                .btn-light{
                    color:$text-white;
                }
            }
        }
    }
}