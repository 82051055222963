@import '../../styles/variable';

.header{
    // border-bottom: 1px solid $border-header;
    padding: px;
    height: 96px;
    background-color: $bg-nav;

    position: fixed;
    width: 100%;
    z-index: 50;


    @media only screen and (min-width :900px ) {
        border-bottom: 1px solid $text-bg-gray;
    }

    /****** link style ****/
    a{
        color:inherit;
        text-decoration: none;
        position : relative;
    }

    .link::after{
    content : '';
    position : absolute;
    width : 100%;
    transform : scaleX(0);
    height : 2px;
    bottom : 0px;
    left : 0;
    background-color : $golden;
    transform-origin : bottom right;
    transition : transform 0.3s ease-out;
    top : 25px;
    }

    .link:hover::after{

        transform : scaleX(1);
        transform-origin : bottom left;
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;

        
        /***logo style*****/
        .logo {
            width: 94px;

            img{
                width: 100%;
                height: 100%;
            }
        }

        /***navigation style****/
        .nav{
            
            display: none;
        } 

        

        @media only screen and (min-width:1024px) {
            .Mobilemenu{
                display: none;
            }
            .nav{
                font-size: 1.3rem;
                width: 100%;
                max-width: 764px;
                gap: 0.3em;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                

                a{
                    color:$text-nav;
                    border-bottom: 2px solid transparent;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                a.active{
                    border-bottom: 2px solid $golden;
                }
                
                a:last-child{
                    background-color: $golden;
                    color:$text-white;
                    padding: 0.3em 0.6em;
                    border-radius: 0.4em;
                    border:2px solid transparent;
                    transition: background 0.3s;
                }
                a:last-child:hover{
                    background-color: white;
                    color:$golden;
                    border:2px solid $golden;
                }

                a.remove{
                    border-bottom: 2px solid transparent;
                }

                button{
                   background-color: transparent;
                   border:none;
                   font-size: inherit;
                   font-weight: inherit;
                   border-bottom: 2px solid transparent;
                   cursor: pointer;
                   display: flex;
                   justify-content: center;
                   align-items: center;   
                   position: relative;
                   padding: 0;
                   color:$text-nav;
                  
                }
                button.link::after{
                    content : '';
                    position : absolute;
                    width : 100%;
                    transform : scaleX(0);
                    height : 2px;
                    bottom : 0px;
                    left : 0;
                    bottom: -10px;
                    background-color : $golden;
                    transform-origin : bottom right;
                    transition : transform 0.3s ease-out;
                    top : 25px;
                }
                button.link:hover::after{
                    transform : scaleX(1);
                    transform-origin : bottom left;
                }
                .triangleContainer{
                    position: absolute;
                    top: 46px;
                    z-index: 2;

                    .triangle{
                        width: 28px;
                        height: 28px;
                        background-color: $bg-nav;
                        transform: rotate(45deg);
                        border-top: 1px solid $border-subNavigation;
                        border-left: 1px solid $border-subNavigation;
                    
                    }
                }
            }
        }

    }


}


.SubNavigation{
    position: absolute;
    top: 67px;

    .SubNavbarContainer{
        width: 100%;
        position: absolute;
        background-color: green;
        height: 100%;
    }
}




  



