@import '../../styles/variable';

.technologyNav{
        display: grid;
        grid-template-columns:  repeat(auto-fit,minmax(350px,1fr));
        grid-auto-rows: auto;
        // grid-auto-rows: 1fr;
        max-width: 1500px;
        margin: auto;
        // padding-bottom: 50px;

    .logo{
         grid-row-start : span 8;
         justify-self: center;
         align-self: center;   
    }   
    .navContainer{
        padding: 0.5em;
        padding-top: 1em;
        border: 0.5px solid #f1f1f1;
        font-family: $text-family;
        cursor: pointer;

        .heading{
            color: $text-primary;
            font-weight: 600;
            font-family: $text-family; 
        }
        .subNav{
            color : $text-subnavigation-menu;
            padding-left: 20px;
            li{
                font-size: 0.8125rem;
                font-weight: 300;
                font-family: $text-family;
                line-height: 1.5;
            }

            li{
                a{
                    position : relative;
                }
            
                a::after{
                content : '';
                position : absolute;
                width : 100%;
                transform : scaleX(0);
                height : 2px;
                bottom : 0px;
                left : 0;
                background-color : $golden;
                transform-origin : bottom right;
                transition : transform 0.3s ease-out;
                top : 15px;
                }
            
                a:hover::after{
                    transform : scaleX(1);
                    transform-origin : bottom left;
                }
            }
        }
    }   
}