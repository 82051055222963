@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    min-width: 320px;

}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa8a8f6;
}


.container {
    width: 100%;
    max-width: 768px;
    margin: auto;
    padding: 0 1em;
    // background-color: yellow;
}

@media only screen and (min-width : 1000px) {
    .container {
        max-width: 980px;
        // background-color: pink;
    }
}

@media only screen and (min-width : 1200px) {
    .container {
        max-width: 1180px;
        // background-color: blue;
    }
}

@media only screen and (min-width : 1600px) {
    .container {
        max-width: 1380px;
        // background-color: green;
    }
}


// @media only screen and (min-width : 1800px) {
//     .container{
//         max-width:1616px;
//         // background-color: red;
//     }
// }


/********mobile and desktop*****/
.mobile {
    display: block;
}

.desktop {
    display: none;
}

@media only screen and (min-width : 992px) {
    .mobile {
        display: none;
    }

    .desktop {
        display: block;
    }
}


$tablet-width : 768px;
$desktop-width: 1024px;

@mixin table {
    @media (min-width : #{$tablet-width}) and (max-width : #{$desktop-width - 1px}) {
        @content
    }
}


@mixin desktop {
    @media (min-width : #{$desktop-width}) {
        @content;
    }
}