@import "../../styles/variable";

.hexagonal-svg{
    position: relative;
    cursor: pointer;
    min-width: 80px;
    width: 118.89px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px;
    // background-color: red;

    @keyframes goldenBorder {
        from{
            stroke: transparent;
            filter: drop-shadow(5px 6px 8px rgb(0 0 0 / 0));
        }
        to{
            stroke: $golden;
            filter: drop-shadow(5px 6px 8px rgb(0 0 0 / 0.20));
        }
    }
 
    svg.blinck{
        animation: goldenBorder 0.95s infinite;
    }

    svg.shadow{
        filter: drop-shadow(5px 6px 8px rgb(0 0 0 / 0.20));
    }

    svg.blur{
        filter: blur(3px) !important;
        -webkit-filter: blur(3px) !important;
        
    }

       

        // background-color: red;
        img{
            margin-bottom: -15px;
            margin-right: -10px;
            position: absolute;
        }




  


    .hexagonal-title{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // background-color: red;
        font-size: 0.8125rem;
        font-weight: 700;
        line-height: 20px;
        text-transform: capitalize;
        color: $text-primary;
        font-family: $text-family;
        box-sizing: border-box;
        max-width: 100px;
    }   

    @media only screen and (min-width : 1024px) {
        .hexagonal-title{
            font-size: 1rem;
        }
    }

    .blur{
        filter: blur(5px) !important;
        text-align: center;
        // -webkit-filter: blur(3px) !important;  
    }
}