@import "../../styles/variable";

.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url('../../assets/Login/loginBg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .login-box {
        background: #ffffff;
        min-width: 400px;
        padding: 30px;
        font-family: $text-family;
        border-radius: 20px;

        h1 {
            font-family: $text-family;
            color: #333333;
            font-weight: 400;
        }

        form {
            font-family: $text-family;

            label {
                font-family: $text-family;
                color: #333333;
            }

            label::before{
                display: none !important;
            }

            input {
                font-family: $text-family;
                display: block;
            }

            button {
                min-width: 400px;
                min-height: 35px;
                border-radius: 15px;
                background-color: #000000c2;
            }

            button:hover {
                background-color: #000000c2 !important;
            }
        }

        p {
            color: #333333;

            span {
                font-weight: 700;
            }
        }
    }
}