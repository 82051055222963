@import "../../styles/variable";

.processSevices_Container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em 1em;
    padding-bottom:3em ;
    background-color: $bg-gray;

    .subtitle{
        font-size: 0.625rem;
        color : $golden;
        font-family: $text-family;
        font-weight: 700;
        line-height: 1.8em;
        margin: 0;
        text-transform: uppercase;
        text-align: center;
    }
    .title{
        font-size: 1.25rem;
        color:$text-primary;
        font-family: $text-family;
        text-align: center;
        margin: 0;
        font-weight: 700;
        margin-bottom: 1em;

    }

    .ImageContainer_img{
        width: 100%;
        margin: auto;
        overflow-x: auto;

        .img{
            min-width: 700px;
            width: 100%;
        }
    }
    .ImageContainer_img::-webkit-scrollbar{
        display: none;
    }

}

@include desktop(){
    .processSevices_Container {
        .subtitle{
            font-size: 1.125rem;
            color : $golden;
            font-family: $text-family;
            font-weight: 700;
            line-height: 1.8em;
            margin: 0;
            text-transform: uppercase;
            text-align: center;

        
        }
        .title{
            font-size: 2.5rem;
            color:$text-primary;
            margin-bottom: 1em;
            font-family: $text-family;
            text-align: center;
            margin-top: -7px;
        }
    }
}