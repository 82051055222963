@import "../../styles/variable";

.completePackageMobileCard{
    .boxContainer{
        min-width: 250px;
        background-color: $bg-white;
        min-height: 250px;
        max-width: 250px;
        max-height: 250px;
        margin-top: 2em;
        height: 250px;
        position: relative;
        
        .box{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: $bg-white;
            z-index: 2;
            position: relative;
            

            .content{
                   padding: 1em;
                   display: none;

               @include keyframes(fadeout){
                    0%{
                        opacity: 0;
                    } 
                    40%{
                        opacity: 0.5;
                    }   
                    80%{
                        opacity: 1;
                    }
               }

                &.active{
                    display: block;
                    animation: fadeout 1s ease-in-out;
                }   
                
                @include desktop(){
                    &.active{
                        display: block;
                        animation: fadeout 1s ease-in-out;
                    } 
                }
                
                .icons{
                    width: 54px;
                    height: 54px;
                    max-width: 54px;
                    margin: auto;
                    margin-bottom: 1em;

                    svg{
                        width: 54px;
                        height: 54px;
                    }
                }
                .heading{
                    font-size: 1.125rem;
                    color:$text-heading;
                }
                p{
                    font-size: 0.625rem;
                    color:$text-gray ;
                }
            }
            

            
            .border-top-left,
            .border-top-right,
            .border-bottom-right,
            .border-bottom-left{
                width: 30px;
                height: 30px;
                // background-color: red;
                display: block;
                position: absolute;
            }
            .border-top-left{
                top: 0;
                left: 0;
                border-top: 1px solid $golden;
                border-left: 1px solid $golden;
            }
            .border-top-right{
                top: 0;
                right: 0;
                border-top: 1px solid $golden;
                border-right: 1px solid $golden;
            }
            .border-bottom-right{
                bottom: 0;
                right: 0;
                border-bottom: 1px solid $golden;
                border-right: 1px solid $golden;
            }
            .border-bottom-left{
                bottom: 0;
                left: 0;
                border-bottom: 1px solid $golden;
                border-left: 1px solid $golden;
            }
        }
       

      

        .pin-material{
            position: absolute;
            top: -40px;
            left: -35px;
            z-index: 1;
        }

        .pin-material-botoom{
            position: absolute;
            bottom: -55px;
            right: -45px;
            z-index: 1;
            // background-color: red;
        }
        

    }
}

@include desktop(){
    .completePackageMobileCard{
        display: none;
    }
}