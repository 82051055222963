@import '../../styles/variable';

.container-our-procedure{
    font-family : $main-fontFamily;
    background-image : url('../../assets/TechnologiesSvg/processBg.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.section-header{
    text-align: center;
    margin-top : 40px;
    display : none;
}

.section-header > :first-child{
    color : $golden;
    font-weight : 700;
    letter-spacing: 1px;
    margin : 0 0 8px 0;
}

.section-header > :last-child{
    font-weight : 700;
    color : $bg-darkBlue;
    margin : 0;
}

.aboutUs-section3{
    @include dFlexDesktop;
    align-items: flex-start;
    gap : 40px;
    padding : 75px 0px 50px 0px;
    max-width : 1380px;
    margin : 0 auto;
}

.section3-part1{
    align-self : center;
}

.section3-part2 > :first-child{
    color : $golden;
    font-weight : 700;
    letter-spacing: 1px;
    margin : 0;
}

#process-description{
    color : $text-heading;
}

.section3-part2 > :last-child{
    color : $text-heading;
}

.section3-part2 h1{
    margin-top : 15px;
    color : $bg-darkBlue;
    font-weight : 700;
    margin : 0;
}

.section3-part2 ol{
    font-size : 18px;
    padding-left : 20px;
}

.section3-part2 li {
    font-weight : 300;
    color : $bg-darkBlue;
    margin-bottom : 10px;
}

.section3-part2 li::marker{
    color : $bg-darkBlue;
    font-weight : 700;
}

.section3-part2{
    align-self: center;
    li{
        span{
            font-weight: 700;
        }
    }
}

.li-header{
    font-weight : 700;
}

@media screen and (max-width : 1180px){
    .aboutUs-section3{
        @include dFlexMobile;
        gap : 0;
        padding-top : 0;
    }

    .section3-part2 > :first-child{
        display : none
    }
    
    .section3-part2 h1{
        display : none;
    }

    .section-header{
        display : block
    }
    
}

@media screen and (max-width : 530px){
    .aboutUs-section3 br{
        display : none;
    }
}

@media screen and (max-width : 575px){
    // svg[viewBox="0 0 562 733"]{
    //     width : 80%;
    //     height : auto;
    //     margin : 0 auto;
    // }

    img{
        width : 80%;
        height : auto;
        margin : 0 auto;
    }

    .section3-part1{
        @include dFlexDesktop;
    }

    .section3-part2{
        padding-left : 20px;
    }

    .section3-part2 ol{
        font-size : 16px;
    }
}