@import "../../styles/variable";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.hero{
    width: 100%;
    height: 100%;
    background-color: $bg-nav;
    // max-height: calc(100vh - 96px);
    // background-image: url(../../assets/home/Hero-Page-Background.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    // min-height: max-content;
    // padding: 0.5em 0;
    overflow: hidden;
    position: relative;

    

    .backgroundVideo{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.4;
            // transform: rotate(90deg);
        }
    }

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 6.2em ;
        position: relative;
        z-index: 2;

        h1{
            margin:0;
            font-family: 'Playfair Display', serif;
            color : $text-primary;
            text-align: center;
            font-size: 1.8rem;
        }

        .subtitle{
            color:#6D788c;
            font-size: 1rem;
        }

        @media only screen and (min-width:1024px) {
            h1{
                font-size: 3.75rem;
                font-weight: 500;
            }
            .subtitle{
                font-size: 1.5rem;
            }
        }
        .linear-animation-container{
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 1.5rem;
            // height:3.5em;
            // background-color: red;
            // gap: 1em;
            overflow: hidden;
            text-align: center;

            .linear-animation{
                list-style: none;
                padding: 0;
                position: relative;
                display: none;
                flex-direction: column;
                position: relative;
                // height: 3.5em;
                // height: 1em;
                text-indent: 15px;
                font-size: 1.8rem;


                @include keyframes(linears){
                    0%{
                        top:12.3em;
                  }
                  12.5%{
                        top:8.8em;
                  }
                  25%{
                    top:5.3em;
                  }
                  37.5%{
                        top:1.7em;
                  }
                  50%{
                      top: -1.8em;
                  }
                  60.5%{
                      top: -5.3em;
                  }
                  70%{
                      top:-8.8em;
                  }
                  80%{
                    top:-12.3em;
                  }
                  98%{
                    top:12.3em;  
                  }
                  100%{
                    top:12.3em;  
                  }
                  }
                  
                

                li{
                    animation: linears 16s infinite;
                    animation-delay: 2s;
                    position: relative;
                    height: 3.5em;
                    display: flex;
                    align-items: center;
                    top: 12.3em;
                    font-weight: 700;
                    font-size: 2.5rem;
                    // background: -webkit-linear-gradient(left, #EF6C6C,#D1AB4A);
                    // background-clip: text;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    text-align: center;
                    color:$golden;
                }
                
               
            }
        }

        @include desktop{
            .linear-animation-container{
                flex-direction: row;
                font-size: 1.5rem;
                height:3.5em;
                // background-color: red;
                // gap: 1em;
                overflow: hidden;
                text-align: center;
    
                .linear-animation{
                    list-style: none;
                    padding: 0;
                    font-size: 2.5rem;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    // height: 3.5em;
                    text-indent: 15px;
            }
        }
        }
        
        .image-container{
            display: flex;
            align-items: flex-end;
            margin: auto;
            margin-top: 1.5em;
            user-select: none;
            justify-content: center;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
           

            .image-mobile{
                display: none;
            }

            @media only screen and (min-width : 1213px) {
                .image-mobile{
                    // background-color: red;
                    position: relative;
                    display: block;
                    max-width: 100%;
                    box-sizing: border-box;
                    justify-content: flex-end;
                    min-height: max-content;
                    display: none;

                
                    img{
                        height: 100%;
                    }

                    .img{
                        position: absolute;
                        top: 2%;
                        left: 5%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img{
                          height: 100%;  
                        }

                        .camera{
                            padding:10px;
                            position: absolute;
                            top: 0%;
                            justify-self: center;       
                        }
                    }
                   
                }
            }

            .image-desktop{
                position: relative;
                // width: 100%; 
                display: flex;
                justify-content: center;
                justify-items: center;
                // background-color: red;
                width: 100%;
                min-height: fit-content;
                // background-color: red;
                // margin-bottom: -30px;
                
                img{
                    width: 100%;
                }


                .img{
                    position: absolute;
                    top: 3%;
                    // background-color: blue;
                    width: 78%;
                    height: 89%;
                    display: flex;
                    justify-content: center;
                    // background-color: green;
                    border-radius: 8px 8px 0 0 ;
                    overflow: hidden;

                    img{
                        width: 100%;
                        // display: none;
                    }

                    .camera{
                        display: none;
                        // padding:10px;
                        position: absolute;
                        top: -5px;
                        justify-self: center;       
                    }
                }            
            }
        }

        @media only screen and (min-width : 1024px) {
            .image-container{
                margin-top: 3em;

            }
        }




        .linear-mobile-animation-Container{
            max-height: 38px;
            padding: 0;
            list-style: none;
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0;


            li{
                    // animation: linears 16s infinite;
                    margin-top: 5px;
                    animation-delay: 1s;
                    position: relative;
                    // height: 3.5em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 1.5rem;
                    background: -webkit-linear-gradient(left, #D1AB4A,#D1AB4A);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
            }

            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 1.5rem;
            // height:3.5em;
            // background-color: red;
            // gap: 1em;
            overflow: hidden;
            text-align: center;

            .linear-animation-mobile{
                display : flex;
                gap : 0px;
                list-style: none;
                padding: 0;
                position: relative;
                flex-direction: column;
                height: 40px;
                // height: 1em;
                text-indent: 15px;
                font-size: 1.8rem;


                @include keyframes(linearsMobile){
                    0%{
                        top:0em;
                  }
                  12.5%{
                        top:-1.5em;
                  }
                  25%{
                    top:-3em;
                  }
                  37.5%{
                        top:-4.5em;
                  }
                  50%{
                      top: -6em;
                  }
                  60.5%{
                      top: -7.5em;
                  }
                  70%{
                      top:-9em;
                  }
                  80%{
                    top:-10.5em;
                  }
                  98%{
                    top:0em;  
                  }
                  100%{
                    top:0em;  
                  }
                  }
                  
                

                li{
                    animation: linearsMobile 12s infinite;
                    animation-delay: 1s;
                    position: relative;
                    height: 3.5em;
                    display: flex;
                    align-items: center;
                    top: 12.3em;
                    font-weight: 700;
                    font-size: 20px;
                    // background: -webkit-linear-gradient(left, #EF6C6C,#D1AB4A);
                    // background-clip: text;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    text-align: center;
                    color:$golden;
                }
                
               
            }
        }

        @media only screen and (min-width : 1024px) {
            .linear-mobile-animation-Container{
                display: none;
            }
        }
    }
}