@import "../../styles/variable";
.container-dashboard {
    display: flex;
    min-height: 100vh;

    .dashboard-left {
        width: 80px;
        border-right: 1px solid #E9EBF0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .dashboard-right {
        flex: 1;
    }
}