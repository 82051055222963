@import '../../styles/variable';

.subnavigation_container{
    position:relative;
    background-color: $bg-nav ;
    width: 100%;
    left: 0;
    right: 0;
    top: -2px;
    border-top: 1px solid $border-subNavigation;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);           
}