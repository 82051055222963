@import "../../styles/variable";

.whychooseus_container{
    height: 100%;
    width: 100%;
    padding-top: 1.5em;
    padding-bottom: 3em;
    background-color: #f4f4f4;
    position: relative;
    overflow: hidden;
    font-family: $text-family;
    

    .container{
        padding: 2em 0 3em 0px ;
        height: 100%;
        width: 100%;
        // background-image: url(../../assets/home/about-background.svg);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        z-index: 2;
        position: relative;   

        .subtitle{      
            color:$golden;
            text-transform: uppercase;
            margin: 0;
            font-family: $text-family;
            font-size: 0.625rem;  
            text-transform: uppercase;
        }
       @include desktop(){
        .subtitle{
            font-size: 1.125rem;
        }
       }
        

        .top{
            display: flex;
            flex-direction: column;
            padding: 0em 0; 
            align-items: center;

            .title{
                margin: 0;
                text-transform: capitalize;
                font-weight: 700;
                font-size: 1.25rem;
                flex: 1;
                color:$text-primary;
                

                @media only screen and (min-width:900px) {
                    .title{
                        font-size: 2.5rem;
                    }
                }
            }
            

            .btn-container{
                flex: 1;
                display: flex;
                gap: 1em;
                // align-self: stretch;
                // margin-top: 2em;

                
                button{
                    background-color: $golden;
                    border:none;
                    outline:none;
                    color:$text-white;
                    padding: 0.6em 0.9em;
                    border-radius:0.3em;
                    font-size:0.875rem;
                    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
                    cursor: pointer;
                    white-space: nowrap;
                    margin-top: 1.5em;

                   
                }
                @media only screen and (min-width : 900px) {
                    button{
                        padding: 0.8em 1.4em;
                        font-size: 1rem;
                    }
                }
                button.active{
                    background-color: $bg-white;
                    color:$golden;
                }
            }
            @media only screen and (min-width : 900px) {
                .btn-container{
                    align-self: center;
                    margin-top:0 ;
                }
                .title{
                    font-size: 2.5rem;
                }
            }

        }

        @include desktop {
            .top{
                flex-direction: row;

                .btn-container{
                    flex: 1;
                    display: flex;
                    gap: 1em;
                    // align-self: stretch;
                    // margin-top: 1em;
    
                    
                    button{
                        margin-top: 0; 
                    }
                    @media only screen and (min-width : 900px) {
                        button{
                            padding: 0.8em 1.4em;
                        }
                    }
                    button.active{
                        background-color: $bg-white;
                        color:$golden;
                    }
                }

            }
        }

        


        .content{
            display: flex;
            // margin: 1em 0;
            margin-top: 1em;
            

            .image-container{
                flex: 1;
                // min-height: 25em;
                // max-height: 29em;
                // background-color: red;

                display: none;
                
                
                .image{
                    // min-height: 20em;
                    height: 100%;
                    opacity: 0.2;

                    img{
                        // background-color: #f4f5f9;
                        // height: 460px;
                        // width: 460px;
                        height: 100%;
                    }
                }
                @media only screen and (min-width:900px) {
                    .image{
                        opacity: 1;
                    }
                }
            }

            .desc{
                flex: 1;
                // position: absolute;
                // height: 450px;
                margin-top: 1em;
           

                .title-desc{
                    max-width: 500px;
    
                    h3{
                        font-size: 2rem;
                        margin: 0;
                        margin-top: 0.5em;
                        margin-bottom: 0.4em;
                        font-size:1.375rem ;
                        font-weight: 600;
                        color: $text-primary;
                    }

                    p{
                        font-size: 0.875rem;
                        color: $text-bg-gray;
                        margin-top: 2em;
                        line-height: 1.5em;
                    }
                }
                

               
            }
            @media only screen and (min-width:1000px) {
                .desc{
                    position: relative;

                    .title-desc{
                        max-width: 500px;
                        
    
    
                        h3{
                            font-size: 2rem;
                            margin: 0;
                            margin-top: 0.5em;
                            margin-bottom: 0.4em;
                            font-size:2.25rem ;
                            font-weight: 600;
                        }
    
                        p{
                            font-size: 1.125rem;
                        }
    
    
                    }
                }
                .image-container{
                    flex: 1;
                    min-height: 25em;
                    max-height: 29em;
                    display: block;
                    // background-color: red;
                    
                    
                    .image{
                        min-height: 20em;
                        height: 100%;
                        opacity: 0.2;
    
                        img{
                            // background-color: #f4f5f9;
                            // height: 460px;
                            // width: 460px;
                            height: 100%;
                        }
                    }
                    @media only screen and (min-width:900px) {
                        .image{
                            opacity: 1;
                        }
                    }
                }
    
            }

        }

       
       


    }

    @include desktop{
        .container{
            align-items: stretch;
            text-align: left;  
            background-image: url(../../assets/home/about-background.svg);
        }
    }





    //image background with image tag
    .backgroundImage{
        position: absolute;
        width: 100%;
        // height: 100%;
        z-index: 1;
        img{
            width: 100%;
            height: 100%;
            opacity: 0.1;
            object-fit: cover;
        }
    }
}

@media screen and (max-width : 650px){
    .whychooseus_container{
        background-color: #fff;
        .container{
            padding-bottom: 0;
        }
    }

    .title-desc{            
        p{
            padding : 15px;
        }
    }
}