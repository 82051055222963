@import "../../styles/variable";

.container-dashboard-top {
    ul {
        list-style: none;
        padding: 0;

        li {
            cursor: pointer;
            font-family: $text-family;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            padding-top: 10px;
            transition: background-color 0.3s ease, border-right-color 0.3s ease;
            border-right: 2px solid transparent;

            p {
                font-size: 14px;
                color: #2B3342;
            }

            svg {
                path {
                    fill: #2B3342;
                }
            }
        }

        .selectedTab {
            border-right: 2px solid #D1AB4A;
            background: #D1AB4A1A;
            color: #D1AB4A;

            svg {
                path {
                    fill: #D1AB4A;
                }
            }

            p {
                color: #D1AB4A;
                font-weight: 700;
            }
        }
    }
}