@import '../../styles/variable';

.sidebar{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    z-index: 52;
    min-height: 400px;

    /***logo style*****/
    .logo {
        min-width: 94px;
        width: 96px;

        img{
            width: 100%;
            height: 100%;
        }
    }
    @include keyframes(slideShow) {
        from{
            width: 0;
        }
        to{
            width: 75%;
        }
    }

    .content{
        width: 75%;
        max-width: 350px;
        background-color: $bg-white;
        height: 100%;
        animation:slideShow 0.2s ease-in-out;
        overflow: hidden;
        
        

        .top_header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:1em;
            height: 96px;
            overflow: hidden;
            


            button{
                @include button()
            } 
        }

        .body{
            pointer-events: none;
            // position: relative;
            font-family: $text-family;
        
           

            .nav{
                display: flex;
                flex-direction: column;
                padding:1.5em 1.4em;
                // gap: 1em;
                color:$text-primary;

                a{
                    color:inherit;
                    text-decoration: none;
                    font-size: 1.2rem;
                    white-space: nowrap;
                    pointer-events: visible;
                    // background-color: red;
                    padding:0.4em 0;
                }

                .btn{
                    background-color: transparent;
                    border: none;
                    display: flex;
                    justify-content: space-between;
                    color:$text-primary;
                    // background-color: red;
                    padding: 0;
                    align-items: center;
                    // background-color: red;

                    .title{
                        color:inherit;
                        text-decoration: none;
                        font-size: 1.2rem;
                        white-space: nowrap;
                        pointer-events: visible;
                        // background-color: red;
                        padding:0.4em 0;
                        width: 100%;
                
                    }
                    .icon{
                        width: 20px;


                        img{
                            width: 100%;
                        }

                        &.active{
                            transform: rotate(180deg);
                        }
                    }

                    
                }
                .serviceSubNavMobile{
                    margin-top: 0;

                    a{
                        display: block;
                        font-family: $text-family;
                        font-weight: 300;
                        font-size: 0.875rem;
                        // background-color: red;
                        margin: 2px;
                        
                    }
                }
            }

            .social{
                position: absolute;
                bottom: 10px;
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 348px;
            
                
            }
        }


       

        
    }


}