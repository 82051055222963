
@import "../../styles/variable";

.recentreviewContainer{
    
    .container{
        padding: 3em 1em;
        font-family: $text-family;
        
     

        .subtitle{
            font-size: 0.625rem;
            color:$golden;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

        }
        .title{
            font-size:1.25rem;
            margin: 0;
            color:$text-heading;
            font-weight: 700;
            text-align: center;

        }

        @include desktop(){
            .subtitle{
                font-size: 1.125rem;
                text-align: left;
            }
            .title{
                font-size: 2.5rem;
                text-align: left;
                margin-top: -7px;
            }
        }

        .content{
            display: block;
            margin-top: 2em;
            justify-content: center;
            align-items: center;
            width: 100%;
            

            .allImageContainer{
                display: flex;
                margin: auto;
                width: fit-content;

                .box{
                    margin-left: -3em;

                    &:first-child{
                        margin-left: 0;
                    }

             

    
                    .imgContainer{
                        min-width: 90px;
                        min-height: 90px;
                        width: 90px;
                        height: 90px;
                        // background-color: red;
                        border-radius: 250px;
                        overflow: hidden;
                        user-select: none;
        
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            user-select: none;
                        }
                    }     
                }
                .activeMobile{
                    position: relative;
                    z-index: 50;
                }
            }
    }


    .mobile{
        
        .descriptionContainer{
            padding-top:25px ;
            position: relative;
            max-width: 400px;
            margin: auto;


            .triangleActive{
                width: 0;
                height: 0;
                position: absolute;
                top: 10px;
                // border-bottom: 16px solid #F4F4F4;
                // border-left: 13px solid transparent;
                // border-right: 13px solid transparent;
                // border-top: 5px solid transparent;

                transform: translateX(52px);
                transition: transform 0.5s;

                .reactTriangle{
                    width: 30px;
                    height: 30px;
                    background-color: #f4f4f4;
                    transform: rotate(45deg);
                    border-top: 1px solid #DDDDDD;
                    border-left: 1px solid #DDDDDD;
                }
            }

            
        }

       

        .desc {
            .name{
                font-weight: 700;
            }
            .descMobile{
                color : $text-primary;
                max-width: 400px;
                margin: auto;
                padding: 0.5em;
                display: none;
                border: 1px solid #DDDDDD;
                background-color: #F4F4F4;
                padding-top: 17px;
            }
            .activeMobile{
                display: block;
            }
        }
    }

        .desktop{
            display: none;
        }
    }




    @include desktop(){

        .container{
            padding: 4em 0;
            display: block;

            .desktop{
                display: block;
            }
        
          
            
            .content{
               display: flex;
                // max-width: auto;    margin: 2.5em 0;
                position: relative;
                justify-content: space-between;
            
                
                
               
                .box{
                    display: flex;
                    gap: 20px;
                    padding: 0.8em;
                    background-color: #F8F8F8;
                    border-radius: 300px;
                    margin-left: -4em;
                    height: 270px;
                    min-width: 270px;
                    width: 270px;
                    overflow: hidden;
                    cursor: pointer;
                    // background-color: red;
                   

                    &:first-child{
                        margin-left: 0;
                    }    

                    .imgContainer{
                        min-width: 248px;
                        min-height: 248px;
                        width: 248px;
                        height: 248px;
                        // background-color: red;
                        border-radius: 250px;
                        overflow: hidden;
                        user-select: none;
        
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            user-select: none;
                        }
                    }
    
                    .desc{
                        // min-width: 320px;
                        //  max-width: 320px;
                        display: none;
                        width: 0px;
                        min-width: 0px;
                        width: 0px;
                        justify-content: center;
                        flex-direction: column;
                        padding-right: 0.5em;
                        
                        
                        
                        .name{
                            font-size: 1.25rem;
                            font-weight: 700;
                            font-family: $text-family-Montserrat;
                            color:$text-heading;
                        }
                        .rating{
    
                        }
                        .telling{
                            font-family: $text-family-Montserrat;
                            font-weight: 400;
                            color:$text-heading;
                        }
                    }
                }

               

                .active{    
                    width: 750px;
                    transition: width 0.5s;

                    .desc{  
                        display: flex;
                        min-width: 320px;
                        width: 320px;
                    }
                    
                }
                
                .notActive{
                    margin-left: -10em;
                }
                @media only screen and (max-width : 1200px) {
                    .box{
                        margin-left: -8.5em;
                    }
                    .active{
                        position: relative;
                        z-index:2 ;
                    }
                    .notActive{
                        margin-left: -15em;
                    }
                 }
            }
            

            .mobile{
                display: none;
            }
          
        }
    }
}