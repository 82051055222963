@import "../../styles/variable";


.completePackageDesktop{
    display: none;
}

@include desktop(){
    .completePackageDesktop{
        display: block;
        margin-top: 2em;

        
        .boxContainer{
            display: flex;
            flex-wrap: wrap;
            gap: 1.5em;
            width: fit-content;
            margin: auto;
            justify-content: center;
            font-family: $text-family;
            position: relative;
          

            .box{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $bg-white;
                position: relative;
                min-width: 358px;
                min-height:295px;
                max-width: 358px;
                max-height: 295px;    
                transition: background 0.3s;    
                cursor: pointer;
                box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
                position: relative;
                z-index: 2;
                background-color: $bg-white;
                

                .contentContainer{
                    overflow: hidden;
                    position: relative;
                    z-index: 2;
                    min-height: 100%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    background-color: white;
                   
                    


                    .content{
                        padding: 2em;
                        padding-top: 2.2em;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        gap: 1em;
                        transform: scale(1);
                        transition: transform 0.1s;
                        
                      
                      
                     .icons{
                         width: 54px;
                         height: 54px;
                         max-width: 54px;
                         margin: auto;
                         // margin-bottom: 1em;
                         display: flex;
                         justify-content: center;
                         align-items: center;
     
                         svg{
                             width: 54px;
                             height: 54px;
                         }
                     }
                     .heading{
                         font-size: 1.5rem;
                         color:$text-heading;
                         font-family: $text-family;
                         font-weight: 600;                
                     }
                     p{
                         font-size: 1rem;
                         color:$text-gray ;
                         font-family: $text-family;
                         font-weight: 600;
                         align-self: baseline;
                         height: 60px;
                         margin-top: -0.2em;
                     }
                 }

                }

                

                .border-top-left,
                .border-top-right,
                .border-bottom-right,
                .border-bottom-left{
                    width: 30px;
                    height: 30px;
                    // background-color: red;
                    display: block;
                    position: absolute;
                }
                .border-top-left{
                    top: 0;
                    left: 0;
                    border-top: 1px solid $golden;
                    border-left: 1px solid $golden;
                }
                .border-top-right{
                    top: 0;
                    right: 0;
                    border-top: 1px solid $golden;
                    border-right: 1px solid $golden;
                }
                .border-bottom-right{
                    bottom: 0;
                    right: 0;
                    border-bottom: 1px solid $golden;
                    border-right: 1px solid $golden;
                }
                .border-bottom-left{
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid $golden;
                    border-left: 1px solid $golden;
                }
                
                
                .hover{
                    display: none;
                } 

                &:hover{
                    // background-color: transparent;
                    // background-color: $bg-white;
                    background-color: $bg-gray;
                    

                    .content{
                        transform: scale(1.04);
                    }

                    .hover{
                        display: block;
                    }
                } 
            }


            .box .pin-material{
                position: absolute;
                top: -40px;
                left: -35px;
                z-index: 1;
                display: none;   
            }
            .box:first-child .pin-material{
                display: block;
            }
            .box .pin-material-botoom{
                position: absolute;
                bottom: -55px;
                right: -45px;
                z-index: 1;
                display: none;
                // background-color: red;
            }
            .box:last-child .pin-material-botoom{
                position: absolute;
                bottom: -55px;
                right: -45px;
                z-index: 1;
                display: block;
                // background-color: red;
            }
            

            

        }
       
       
    }
}

