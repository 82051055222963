@import "../../styles/variable.scss";
@import '../../styles/Global.scss';

.container-join-our-team{
    display : grid;
    place-items: center;
    font-family: $text-family;
    // margin-bottom: 70px;
    margin : 0 auto 70px auto;
    background: url('../../assets/OurTeam/Join\ Our\ team.svg');
    background-repeat: no-repeat;
    max-width : 1920px;
}

.section1-info{
    text-align: center;
    margin : 80px 0 50px 0;

    p{
        margin: 0;
        color : $golden;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    h1{
        margin: 0;
        color : $text-heading;
        font-size : 40px;
    }
}

@media screen and (max-width : 1024px) {
    .section1-info{
        p{
            font-size : 15px;
        }

        h1{
            font-size : 30px;
        }

        br{
            display : none;
        }
    }

    .section1-hero{
        svg{
            width : 100%;
            height: 50vw;
        }
    }
}

@media screen and (max-width : 480px){
    .container-join-our-team{
        background: url('../../assets/OurTeam/BgMobile.svg');
        background-repeat: no-repeat;
        
    }

    .section1-info{
        p{
            font-size : 13px;
        }

        h1{
            font-size : 20px;
        }
    }
}