@import "../../styles/variable";

.ImageDescription-container{
    background-color: $bg-white;
    width: 100%;

    .container{
        display: flex;
        justify-content: space-between;
        position: relative;

        .left{
            // background-color: red;
            font-family: $main-fontFamily;
            flex: 1;
            z-index: 2;
            position: relative;
            padding: 3.5em 0;
            

                .subtitle{
                    font-size: 0.625rem;
                    color : $golden;
                    font-family: $text-family;
                    font-weight: 700;
                    line-height: 1.8em;
                    margin: 0;
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: 1px;
                }
                .title{
                    font-size: 1.25rem;
                    color:$text-primary;
                    text-align: center;
                    margin: 0;
                }
                p{
                    font-weight: 300;
                    color:$text-primary;
                    font-size: 14px;
                }
                ul{
                    color:$text-primary;
                }

                @include desktop() {
                    .subtitle{
                        font-size: 1.125rem;
                        color : $golden;
                        font-family: $text-family;
                        font-weight: 700;
                        line-height: 1.8em;
                        margin: 0;
                        text-transform: uppercase;
                        text-align: left;
                    }
                    .title{
                        font-size: 2.5rem;
                        color:$text-primary;
                        font-weight: 700;
                        margin: 0;
                        margin-bottom: 0.5em;
                        text-align: left;
                        margin-top: -7px;
                    } 
                    p{  
                        max-width: 550px;
                        font-family: $main-fontFamily;
                        font-size: 1.125rem;
                        font-weight: 300;
                    }
                }
        }

        .right{
            flex: 1;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0    ;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            height: 100%;
            padding: 0 1em;
            overflow: hidden;

            img{
                height: 100%;
                width: fit-content;
                max-width: 480px;
                opacity: 0.2    ;
                margin: auto;
                user-select: none;
            }
        }

        @include desktop(){
            .right{
                flex: 1;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
    
    
                img{
                    width: 100%;
                    max-width: 500px;
                    opacity: 1;
                }
            }
        }

    }
}

.container-technology{
    .technology-image{
        align-self: center;
    }
}