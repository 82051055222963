@import "../../styles/variable";


.toolstouse{
    width: 100%;
    height: 100%;
    background-color: $bg-gray;
    background-image: url('../../assets/services/Tools/toolsweuse-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .container{
        width: 100%;
        padding: 3em 0 ;
        font-family: $text-family;
        position: relative;
        z-index: 2;




        .subtitle{
            font-size: 0.625rem;
            color : $golden;
            font-family: $text-family;
            font-weight: 700;
            line-height: 1.8em;
            margin: 0;
            text-transform: uppercase;
            text-align: center;
        }
        .title{
            font-size: 1.25rem;
            color:$text-primary;
            font-family: $text-family;
            text-align: center;
            margin: 0;

        }


        @include desktop(){
            .subtitle{
                font-size: 1.125rem;
                color : $golden;
                font-family: $text-family;
                font-weight: 700;
                line-height: 1.8em;
                margin: 0;
                text-transform: uppercase;
              
            }
            .title{
                font-size: 2.5rem;
                color:$text-primary;
                margin-bottom: 1em;
                font-family: $text-family;
                margin-top: -7px;
            }  
        }


        .boxContainer{
            width: 100%;
            padding: 1em 0;
            position: relative;
            z-index: 2;

            .items{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2em;
                width: 100%;
                flex-flow: row;
                margin-bottom: 1.5em;
                flex-wrap: wrap;
              
              
    
                .item{
                    min-width: 220px;
                    max-width: 300px;
                    flex: 1;
                    min-height: 170px;
                    border-radius: 10px;
                    box-shadow: 4px 6px 25px rgba(0, 0, 0, 0.1);
                    border : 1px solid $border-svg ;
                    display: none;
                    background-color: white;
    
                    &.active{
                       display: block;
                    //    background-color: $border-svg ;
                    }
    
                    svg,img {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 1.3/1;
                    }
                }
    
                @media only screen and (min-width : 1200px) {
                    .item{
                        min-width: 230px;
                        min-height: 210px;
                     
                        
                    }
                }
                @media  only screen  and  (min-width : 1600px) {
                    .item{
                        min-width: 300px;
                        min-height: 250px;
                    }
                }

                
    
            } 
            
            
        }

        .desktopDisplay{
            display: none;
        } 
        
       

        @include desktop(){
            .desktopDisplay{
                display: block;
                

                .items{
                    position: relative;
                    z-index: 2; 
                }

                .pin-material{
                    position: absolute;
                    top: -20px;
                    left: 0px;
                    z-index: 1;
                }
                &:first-child .pin-material{
                    display: block;
                }
                 .pin-material-botoom{
                    position: absolute;
                    bottom: -20px;
                    right: 0px;
                    z-index: 1;
                    // display: none;
                    // background-color: red;
                }
                &:last-child .pin-material-botoom{
                    position: absolute;
                    bottom: -55px;
                    right: -45px;
                    z-index: 1;
                    display: block;
                    // background-color: red;
                }

                
            }  
        }
    }


    @include desktop(){
        .container{
            padding: 3em 0;



        }
    }


}

.toolstouse{
    .container{
        .testing-box{
            max-width : 320px;
            max-height : 250px;
            margin : 0 auto;
        }
    }
}