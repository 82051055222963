@import "../../styles/variable";

.technologies-container{
    padding: 2.5em 0;
    display: none;
    font-family: $text-family;



    .container{

        .title,.subtitle{
            text-align: center;
            margin: 0;
        }

        .title{
            font-size: 42px;
            font-weight: 700;
            color:$text-primary;
        }
        .subtitle{
            color:$golden;
            text-transform: uppercase;
            font-weight: 700;
            font-family: $text-family;
            font-size: 1.125rem;
        }


        .animation-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3em;
            overflow: hidden;
            // background-color: green;
            width: 100%;

            

            @include keyframes(disolve){
                from{
                    transform: scale(1.4);
                    opacity: 0.2;
                }
                to{
                    transform: scale(1);
                    opacity: 1;
                }   
              }

              .slider{
                display: flex;
                justify-content: space-between;
                align-items: center;
                    .icons{
                        width: 250px;
                        height: 120px;
                        animation: disolve 0.4s ease-out;
                        position: relative;
                        min-height: 10em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        // background-color: red;
                        

                        img{
                            width: 100%;
                            background-color: $bg-white;
                        }
            }
        }


            @keyframes sliderAnimate {
                0%,1%,2%,3%,4%,5%,6%,7%,8%,9%,10%,11%,12%,13%,14%,15%,16%,17%,18%,19%,20%,21%,22%,23% {
                  transform: translateX(0);
                   
                }
                25%,26%,27%,28%,29%,30%,31%,32%,33%,34%,35%,36%,37%,38%,39%,40%,41%,42%{
                    transform: translateX(-100%);
                }
                45%,46%,47%,48%,49%,50%,51%,52%,53%,54%,55%,56%,57%,58%,59%,60%,61%,62%,63%,64%,65%,66%,67%,68%,69%,70%,71%,72%,73%,74%,75%,76%,77%,78%,79%{
                    transform: translateX(-200%);
                }
                80%,81%,82%,83%,84%,85%,86%,87%,88%,89%,90%,91%,92%,93%,94%,95%,96%,97%,98%,99% {
                    transform: translateX(-300%);
                }
                
              }


            .slider{
                display: flex;
                min-width: 100%;
                animation: sliderAnimate 10s infinite;
                

                
            }





        }
    }

}

@media only screen and (min-width : 1024px) {
    .technologies-container{
        display: block;
        padding: 3.5rem;
    }
}