@import "../../styles/variable";

.container-our-vacancies {
    font-family: $text-family;
    max-width: 1920px;
    display: grid;
    place-items: center;
    padding: 75px 0 75px 0;
    background: url('../../assets//OurVacancies/BgOurV.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
}

.vacancies-text {
    text-align: center;

    p {
        margin: 0;
        color: $golden;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1.5px;
    }

    h1 {
        margin: 0;
        color: $text-heading;
        font-size: 40px;
    }
}

.vacancies-info {
    margin-top: 75px;

    h2 {
        color: $text-heading;
        font-family: $text-family;
    }

    li {
        list-style: none;
        cursor: pointer;
        max-width: 1180px;

        .vacancy-details {
            box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.15), 0px 1px 0px rgba(0, 0, 0, 0.02);
            border-radius: 10px;
            background-color: $bg-white;
            font-size: 18px;
            color: $text-heading;
            padding: 40px 40px 60px 40px;
            margin-bottom: 25px;
            pointer-events: none;
            min-width: 1170px;

            p {
                font-weight: 600;
            }

            span,
            li {
                font-weight: 300;
            }

            li {
                list-style: initial;
            }
        }
    }

    .wrapper-card {
        background-color: $text-white;
        border-radius: 10px;
        box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.15), 0px 1px 0px rgba(0, 0, 0, 0.02);
        padding: 15px 45px 15px 20px;
        margin-bottom: 20px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // gap : 20vw;
        min-width: 1180px;
        position: relative;

        .container-modal {
            position: fixed;
            z-index: 51;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #00000099;
            // transform: translate(-50%, -50%)
            display: grid;
            place-items: center;

            .modal-hiring-form {
                max-width: 500px;
                box-shadow: 4px 6px 25px rgba(106, 106, 106, 0.25);
                border-radius: 10px;
                cursor: auto;

                .form-row1 {
                    padding: 10px 0 10px 0;
                    display: flex;
                    justify-content: center;
                    background: url('../../assets/OurVacancies/formBg.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    position: relative;

                    img {
                        max-width: 130px;
                    }

                    .close-form {
                        position: absolute;
                        margin-right: 20px;
                        margin-top: 10px;
                        color: $text-white;
                        font-size: 30px;
                        right: 0;
                        cursor: pointer;
                    }
                }

                .form-row2 {
                    padding: 30px 25px 40px 25px;
                    background-color: $bg-white;
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;

                    h1 {
                        font-size: 28px;
                        font-weight: 700;
                        color: $golden;
                        text-align: left;
                        margin: 0
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: $text-primary;
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        label {

                            // input{
                            //     min-width : 100%;
                            //     padding : 10px 7px;
                            //     border-radius: 5px;
                            //     border : 1px solid #DBDBDB;
                            // }
                            input[type="file"] {
                                position: relative;
                            }

                            input[type="file"]::file-selector-button {
                                width: 136px;
                                color: transparent;
                            }

                            input[type="file"]::before {
                                position: absolute;
                                pointer-events: none;
                                top: 10px;
                                left: 16px;
                                height: 20px;
                                width: 20px;
                                content: "";
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
                            }

                            input[type="file"]::after {
                                position: absolute;
                                pointer-events: none;
                                top: 11px;
                                left: 40px;
                                color: #0964b0;
                                content: "Upload File";
                            }


                            /* file upload button */
                            input[type="file"]::file-selector-button {
                                border-radius: 4px;
                                padding: 0 16px;
                                height: 40px;
                                cursor: pointer;
                                background-color: white;
                                border: 1px solid rgba(0, 0, 0, 0.16);
                                box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
                                margin-right: 16px;
                                transition: background-color 200ms;
                            }

                            /* file upload button hover state */
                            input[type="file"]::file-selector-button:hover {
                                background-color: #f3f4f6;
                            }

                            /* file upload button active state */
                            input[type="file"]::file-selector-button:active {
                                background-color: #e5e7eb;
                            }

                            input::placeholder {
                                font-family: $main-fontFamily;
                                color: #DBDBDB;
                            }
                        }

                        .hiring-form-btn-container {
                            display: flex;
                            justify-content: space-around;

                            button {
                                padding: 10px 20px;
                                margin-top: 20px;
                                font-family: $main-fontFamily;
                                font-size: 16px;
                                font-weight: 700;
                                min-width: 193px;
                            }

                            :first-child {
                                background-color: transparent;
                                border: 1px solid #F1F1F1;
                                border-radius: 10px;
                                color: $golden;
                                cursor: pointer;
                            }

                            :last-child {
                                background-color: $golden;
                                border: 1px solid #F1F1F1;
                                border-radius: 10px;
                                color: $text-white;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }


        }

        .container-success-modal {
            position: fixed;
            z-index: 51;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #00000099;
            // transform: translate(-50%, -50%)
            display: grid;
            place-items: center;

            .container-confetti {
                position: absolute;
                top: 0;
                left: 0;
            }

            .modal-succes {
                max-width: 500px;
                box-shadow: 4px 6px 25px rgba(106, 106, 106, 0.25);
                border-radius: 10px;
                cursor: auto;

                .success-row1 {
                    padding: 10px 0 10px 0;
                    display: flex;
                    justify-content: center;
                    background: url('../../assets/OurVacancies/formBg.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    position: relative;

                    img {
                        max-width: 130px;
                    }

                    .close-form {
                        position: absolute;
                        margin-right: 20px;
                        margin-top: 10px;
                        color: $text-white;
                        font-size: 30px;
                        right: 0;
                        cursor: pointer;
                    }
                }

                .success-row2 {
                    padding: 30px 25px 40px 25px;
                    background-color: $bg-white;
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;

                    img {
                        display: block;
                        margin: 0 auto;
                    }

                    h1 {
                        font-size: 28px;
                        font-weight: 700;
                        color: $golden;
                        text-align: center;
                        margin: 0
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        color: $text-primary;
                    }

                    button {
                        padding: 10px 20px;
                        margin-top: 20px;
                        font-family: $main-fontFamily;
                        font-size: 16px;
                        font-weight: 700;
                        min-width: 193px;
                        background-color: transparent;
                        border: 1px solid #F1F1F1;
                        border-radius: 10px;
                        color: $golden;
                        cursor: pointer;
                    }
                }
            }
        }

    }

    .info-part1 {

        .part1-row1 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;

            p {
                font-weight: 700;
                font-size: 18px;
                color: $text-heading;
            }

            span {
                border-radius: 20px;
                font-size: 10px;
                font-weight: 700;
                padding: 2px 6px 2px 6px;
                text-transform: uppercase;
                letter-spacing: 1px;
                background-color: #FB8181;
                color: $text-white;
            }
        }
    }

    .part1-row2 {
        display: flex;
        gap: 20px;
        margin-top: -10px;
        color: $text-primary;

        p {
            border-right: 1px solid #DDDDDD;
            padding-right: 20px;
            font-size: 14px;
            font-weight: 700;
            white-space: nowrap;

            span {
                font-weight: 400;
            }
        }

    }

    .info-part2 {
        display: flex;
        gap: 20px;
        align-items: center;

        span {
            padding: 10px 15px 10px 15px;
            border-radius: 8px;
            background-color: $golden;
            color: $text-white;
            font-weight: 700;
            white-space: nowrap;
        }
    }
}

@media screen and (min-width : 1920px) {
    .vacancies-info {
        .wrapper-card {
            gap: 300px;
        }
    }
}

@media screen and (max-width : 1300px) {

    .vacancies-info {
        ul {
            padding: 0;
        }

        .container-card {
            width: 80vw;

            .info-part2 {
                span {
                    font-size: 12px;
                }
            }
        }

        .vacancy-details {
            min-width: 0px !important;
            padding: 20px 20px 30px 20px !important;

            p {
                font-size: 12px;
            }

            li {
                font-size: 12px;
            }
        }

        .wrapper-card {
            min-width: 100%;
        }

        .part1-row1 {
            p {
                font-size: 14px;
            }


        }

        .part1-row2 {
            flex-direction: column;
            gap: 0;


            p {
                margin: 0;
                border-right: none;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width : 1024px) {
    .vacancies-text {
        p {
            font-size: 15px;
        }

        h1 {
            font-size: 30px;
        }

        br {
            display: none;
        }
    }
}

@media screen and (max-width : 500px) {
    .vacancies-info {
        .info-part2 {
            flex-wrap: wrap;

            span {
                padding: 5px 10px 5px 10px;
            }

            img {
                height: auto;
                width: 20px;
            }
        }
    }
}

@media screen and (max-width : 480px) {
    .vacancies-text {
        p {
            font-size: 13px;
        }

        h1 {
            font-size: 20px;
        }
    }
}