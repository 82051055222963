@import "../../../styles/variable";

.container-employee-benefits {
    display: flex;
    justify-content: center;
    font-family: $text-family;
    background-image: url('../../../assets//EmployeeBenefits/Svgs/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.eb-row3-mobile {
    display: none;
}

.wrapper-employee-benefits {
    padding: 75px 0 75px 0;

    .eb-row1 {
        margin-bottom: 25px;

        p {
            font-size: 18px;
            font-weight: 700;
            color: $golden;
            margin: 0;
        }

        h1 {
            font-size: 40px;
            font-weight: 700;
            color: $text-heading;
            margin: 0;
        }
    }

    .eb-row2 {
        display: flex;
        justify-content: center;
        align-items: flex-start;


        .item1-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;

            img {
                margin: 0;
            }

            p {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                color: $golden;
                background-color: $bg-darkBlue;
                padding: 15px 0 15px 0;
                margin: -45px 0 0 0;
            }
        }

        .row2-item2 {
            display: flex;

            ul {
                list-style: none;
                margin: 0;

                li {
                    display: flex;
                    border: 1px solid #CBCBCB;
                    box-shadow: 4px 6px 25px rgba(175, 196, 236, 0.1);
                    background-color: $bg-white;
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 35px;
                    padding: 10px 15px 10px 15px;

                    #work-life {
                        margin-right: 10px;
                    }

                    #transparent {
                        margin-right: 10px;
                    }

                    .wrapper-svg {
                        align-self: center;
                        border-right: 1px solid #D2D2D2;
                        padding-right: 15px;

                    }

                    p {
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 22px;
                        font-weight: 400;
                        align-self: center;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width : 1024px) {
    .wrapper-employee-benefits {
        .eb-row1 {
            p {
                font-size: 15px;
            }

            h1 {
                font-size: 30px;
            }
        }
    }
}


@media screen and (max-width : 1300px) {
    .wrapper-employee-benefits {
        .eb-row2 {
            display: none;
        }

        .eb-row3-mobile {
            display: block;

            .box {
                .heading {
                    font-weight: 600;
                    text-align: center;
                }
            }

            .completePackageMobileCard {
                display: block;
            }
        }
    }
}

@media screen and (max-width : 480px) {
    .wrapper-employee-benefits {
        .eb-row1 {
            p {
                font-size: 13px;
            }

            h1 {
                font-size: 20px;
            }
        }
    }
}