@import "../../styles/variable";

.technologiesMobileCard{
    margin-top: 2em;

    .boxContainer{
        min-width: 280px;
        // background-color: $bg-white;
        min-height: 280px;
        max-width: 280px;
        max-height: 280px;
        margin-top: 2em;
        height: 280px;
        position: relative;
        margin: auto;


        .pin-material{
            position: absolute;
            top: -40px;
            left: -35px;
            z-index: -2;
        }

        .pin-material-botoom{
            position: absolute;
            bottom: -55px;
            right: -45px;
            z-index: -2;
        }

        .box{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: $bg-white;
            z-index: 2;
            position: relative;
            width: 100%;    
            box-shadow: 4px 6px 25px rgba(0, 0, 0, 0.1);
            border-radius: 10px;    
            border : 1px solid $border-svg ;
            box-sizing: border-box;  
            z-index: 10; 
            

            .content{
                   display: none;
                   background-color: white;

               @include keyframes(fadeout){
                    0%{
                        opacity: 0;
                    } 
                    40%{
                        opacity: 0.5;
                    }   
                    80%{
                        opacity: 1;
                    }
               }

               img{
                width: 100%;
                height: 100%;
               }

                &.active{
                    display: block;
                    animation: fadeout 1s ease-in-out;
                }   
                
                @include desktop(){
                    &.active{
                        display: block;
                        animation: fadeout 1s ease-in-out;
                    } 
                }
                
            }
            
        }
       

      

        

    }
}

@include desktop(){
    .technologiesMobileCard{
        display: none;
    }
}