@import "../../styles/variable";


iframe{
    min-width : 460px;  
}

.aboutUs-section7-container{
    font-family : $main-fontFamily;
    max-width : 1200px;
    margin : 50px auto;
    padding : 50px;
    border-radius : 50px;
    box-shadow : 0px 141px 200px -80px rgba(25, 58, 75, 0.3);
    background: linear-gradient(to left, #2B3342 30%, transparent 10%);
}

.aboutUs-section7{
    @include dFlexDesktop;
    gap : 50px;
    align-items: stretch;
}

.section7-part1{
    @include dFlexDesktop;
    align-items : flex-start;
    justify-content : space-between;
    flex-direction : column;
}

.part1-item1 h1{
    font-weight : 700;
    font-size : 40px;
    color : $text-heading;
}

.part1-item1 span{
    color : $golden;
    margin-right : 10px;
}

.part1-item1 h1{
    margin : 0;
}

.part1-item1 p{
    font-size : 14px;
    font-weight : 400;
    color : $text-heading;
}

.section7-part1 form input, textarea{
    border : 1px solid #D9DEE2;
    width : 100%;
    min-height : 40px;
    margin-top : 6px;
    font-family: $main-fontFamily;
    padding-left : 10px;
}

form textarea{
    display : block;
    min-height : 140px;
    resize: none;
}

form label{
    color : $text-heading;
    font-weight : 600;
}

.part1-item2{
    form{
        button{
            border : none;
            min-width : 100%;
            background-color : $golden;
            color : $text-white;
            min-height : 60px;
            border-radius : 7px;
            font-size : 20px;
            font-weight : 700;
            font-family : $main-fontFamily;
            cursor: pointer;
            margin-top : 40px;
        }
    }
}

.part1-item2{
    width : 100%;
}

form{
    width : 100%;
}

.formElement{
    margin-top : 25px;
}


#asterisk{
    color : red;
}

.part1-item3{
    @include dFlexDesktop;
    justify-content : space-between;
    gap : 15px;
    min-width : 100%;
    margin-top : 20px;
    font-size : 13px;
    font-weight : 400;
}

.part1-item3 p{
    margin : 0;
    font-size : 14px;
    font-weight : 600;
}

.social-info-1, .social-info-2, .social-info-3{
    @include dFlexDesktop;
    gap : 10px;
}

.social-info-1-text > :first-child, .social-info-2-text > :first-child, .social-info-3-text > :first-child{
    color : $text-heading;
}

.social-info-1-text > :last-child, .social-info-2-text > :last-child, .social-info-3-text > :last-child{
    color : $golden;
}

.social-info-3-text > :last-child a{
    text-decoration : underline;
    color : $golden;
}

.section7-part2{
    align-self : center;
}

@media screen and (max-width : 1440px){
    iframe{
        height : 540px;
    }
}

@media screen and (max-width : 1180px){
    .section7-part2{
        display : none;
    }

    .aboutUs-section7-container{
        background : none !important;
        box-shadow : none !important;
    }

    .section7-part1{
        border : 2px solid $border-contactform-mobile;
        padding : 40px 30px 40px 30px;
        border-radius : 15px;
    }

}

@media screen and (max-width : 1024px){
    .section7-part1{
        .part1-item1{
            h1{
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width : 648px) and (min-width : 461px){
    .part1-item3{
        flex-wrap: wrap;
    }

    .social-info-3{
        margin : 0 auto;
    }
}

@media screen and (max-width : 460px){
    .part1-item3{
        flex-wrap: wrap;
    }

    .part1-item1 h1{
        font-size : 32px;
    }

    form button{
        min-height : 50px;
        font-size : 16px;
    }
}

@media screen and (max-width : 535px){
    .part1-item1 p br{
        display : none;
    }
}

@media screen and (max-width : 650px){
    .aboutUs-section7-container{
        margin-top : 0;
    }
}