@import '../../styles/variable';

.serviceNav{
        display: grid;
        grid-template-columns:  repeat(auto-fit,minmax(300px,1fr));
        grid-auto-rows: auto;
        max-width: 1500px;
        margin: auto;
                  

    .logo{
         grid-row-start : span 8;
         justify-self: center;
         align-self: center;   
    }   
    .navContainer{
        padding: 0.5em;
        padding-top: 1em;
        border: 0.5px solid #f1f1f1;
        font-family: $text-family;
        cursor: pointer;
      

        &:hover{
            background-color: #afc4ec22;
            position: relative;
            z-index: 2;
        }

        .heading{
            color: $text-primary;
            font-weight: 600;
            font-family: $text-family; 
        }
        .subNav{
            color : $text-subnavigation-menu  ;
        

            li{
                font-size: 0.8125rem;
                font-weight: 300;
                font-family: $text-family;
                line-height: 1.5;
            }
        }
    }   
}