@import "../../styles/variable";

.container-hiring-process{
    font-family: $main-fontFamily;
    padding : 60px 0px 60px 0px;
}

.wrapper-hiring-process{
    margin: 0 auto;
    max-width : 1200px;

    p{
        font-size: 18px;
        font-weight: 700;
        color : $golden;
        margin: 0;
    }

    h1{
        color : $text-heading;
        font-size: 40px;
        font-weight: 700;
        margin : 0;
    }

    img{
        margin-top : 20px;
    }

    .mobile-hiring-process-svg{
        display : none;
    }
}

@media screen and (max-width : 1260px){
    .wrapper-hiring-process{
        img{
            max-width : 100%;
        }
    }
}

@media screen and (max-width : 1024px){
    .wrapper-hiring-process{
        p{
            font-size : 15px;
            text-align: center;
        }

        h1{
            font-size: 30px;
            text-align: center;
        }
        
    }
}

@media screen and (max-width : 764px){
    .hiring-process-gif{
        display : none;
    }

    .wrapper-hiring-process{
        .mobile-hiring-process-svg{
            display: block;
            overflow-y: none;
            overflow-x: auto;
        }

        .mobile-hiring-process-svg::-webkit-scrollbar{
            height: 4px;
            width: 4px;
            background: gray;
        }

        .mobile-hiring-process-svg::-webkit-scrollbar-track {
            background: #f1f1f1; 
          }

        .mobile-hiring-process-svg::-webkit-scrollbar-thumb {
            background: #888; 
          }
    }

}

@media screen and (max-width : 480px){
    .wrapper-hiring-process{
        p{
            font-size : 13px;
        }

        h1{
            font-size: 20px;
        }
        
    }
}


