@import "../../styles/variable";

.completepackage{
    background-image: url(../../assets/completepackage/completepackage.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 3em ;
    background-color: #f4f4f4;


  .container{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4em ;
    z-index: 2;



    .subtitle{
        color : $golden;
        font-family: $text-family;
        font-weight: 700;
        line-height: 1.8em;
        margin: 0;
        font-size: 0.625rem;
    }
    .title{
        color:$text-primary;
        margin: 0;
        margin-bottom: 1em;
        font-size: 1.25rem;
        font-family: $text-family;
    }
    @include desktop(){
        .subtitle{
            color : $golden;
            font-family: $text-family;
            font-weight: 700;
            line-height: 1.8em;
            margin: 0;
            font-size: 1.125rem;
        }
        .title{
            color:$text-primary;
            margin: 0;
            font-size: 2.5rem;
            // margin-top:0.5em;
            margin-bottom: 1em;
        
        }
    }

    

   

  }

}

@media screen and (max-width : 650px){
    .completepackage{
        .container{
            h2{
                margin : 0 !important;
            }
        }
    }
}