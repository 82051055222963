@import "../../styles/variable";

.main-dashboard {
    .section-info {
        .user-info {
            border-bottom: 2px solid #F0F0F0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 15px 0 15px;
            font-family: $text-family;
            font-size: 14px;
            font-weight: 600;
            gap: 10px;

            button {
                font-family: $text-family;
                border: none;
                background: #E72929;
                color: #F0F0F0;
                font-weight: 700;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
            }
        }

        .view-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px 0 15px;
            font-family: $text-family;
            border-bottom: 2px solid #F0F0F0;

            p {
                font-weight: 600;
            }

            button {
                border: none;
                background-color: #D1AB4A;
                font-family: $text-family;
                color: #fff;
                border-radius: 5px;
                padding: 10px 8px 10px 8px;
                cursor: pointer;
                font-weight: 600;
            }
        }
    }

    .container-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        h2 {
            font-family: $text-family;
            color: #2B3342;
        }

        .info-box {
            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 20px 10px 20px;
            margin: 15px;
            min-width: 45vw;
            box-sizing: border-box;
            border-radius: 6px;
            color: #2B3342;

            h3 {
                margin: 0;
            }

            .hiring-details {
                display: flex;
                flex-direction: column;
                font-family: $text-family;

                .details-bottom {
                    display: flex;
                    gap: 40px;

                    p {
                        font-weight: 600;
                        border-right: 1px solid #DDDDDD;
                        padding-right: 30px;

                        span {
                            font-weight: 500;
                        }
                    }

                    p:last-child {
                        border-right: none;
                    }
                }
            }

            .action-btns {
                display: flex;
                gap: 5px;

                svg {
                    cursor: pointer;
                    height: auto;
                    width: 25px;
                }
            }
        }
    }

    .container-resumes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .candidate-info-box {
            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            padding: 20px;
            margin: 20px 20px 10px 20px;
            font-family: $text-family;
            min-width: 45vw;
            color: #2B3342;
            border-radius: 6px;

            .candidate-info {
                display: flex;
                gap: 40px;

                .item-box {
                    display: flex;
                    flex-direction: column;

                    span:first-child {
                        font-weight: 600;
                    }
                }
            }

            svg {
                cursor: pointer;
                height: auto;
                width: 25px;
            }
        }
    }
}

.container-new-pos-draw {
    font-family: $text-family;

    h2 {
        background-color: #F4F4F4;
        text-align: center;
        font-family: $text-family;
        color: #262626;
    }

    .input-row-1,
    .input-row-2 {
        display: flex;
        justify-content: space-between;
        gap: 15px;

        div {
            flex: 1;
        }
    }

    form {
        font-family: $text-family;

        label {
            font-family: $text-family;
            color: #333333;
        }

        label::before {
            display: none !important;
        }

        input {
            font-family: $text-family;
            display: block;
        }

        .btn-draw {
            display: flex;
            justify-content: center;
            gap: 15px;

            button {
                font-family: $text-family;
                border: none;
                padding: 10px;
                font-weight: 600;
                font-size: 16px;
                min-width: 80px;
                cursor: pointer;
            }

            button:first-child {
                border: 2px solid #D1AB4A;
                font-size: 16px;
                border-radius: 5px;
                color: #D1AB4A;
                background: none;
            }

            button:last-child {
                border: 2px solid #D1AB4A;
                font-size: 16px;
                color: #fff;
                border-radius: 5px;
                background: #D1AB4A;
            }
        }
    }
}


@media screen and (max-width : 1530px) {
    .main-dashboard {
        .container-info {
            .info-box {
                min-width: 600px;
                max-width: 600px;
                gap: 0px;

                .hiring-details {
                    .details-bottom {
                        gap: 10px;

                        p {

                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}