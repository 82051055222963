
@import "../../styles/variable";


.scrolltoptopContainer{
    display: none;
}

@include desktop(){
    .scrolltoptopContainer{
        display: flex;
        height: 60px;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
        font-weight: 500;
        font-family: $font-family-poppins;
        font-size: 1.125rem;
        cursor: pointer;
        user-select: none;


        .scroll{
            background-color: $bg-white;
            color:$text-primary;
            min-width: 65px;
            border-radius: 5px 0px 0px 5px;  
            height: 50px;     
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 7px;
        }

        .up{
            background-color: $bg-darkBlue;
            color:$text-white;
            min-width: 65px;
            line-height: 27px;
            height: 50px;
            box-sizing: border-box;
            border-radius: 0px 5px 5px 0px; 
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 7px;

        }
    }
    
}

