@import '../../styles/variable';

.container-hireusnow{
    color:$text-white;
    font-family: $main-fontFamily;
    display: flex;
    justify-content: center;
    gap:200px;
    padding-top:50px;
    padding-bottom: 50px;
    background-color: $bg-darkBlue;

    h1{
        font-size:36px;
        margin:0;
        margin-bottom: 10px;
        font-weight: 600;
    }

    p{
        font-size: 18px;
        margin: 0;
        font-weight: 400;
    }

    .section2-hireusnow{
        align-self: center;
        button{
            background-color: $golden;
            color:$text-white;
            font-family: $main-fontFamily;
            font-weight:600;
            font-size: 18px;
            padding: 12px 24px 12px 24px;
            border:none;
            cursor : pointer;
        }
    }
}
