@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

$golden : #D1AB4A;
$text-heading : #2B3342;
$text-bg-white : #636363;
$text-bg-gray : #565656;
$logo-text-gray : #858384;
$text-gray : #858384;
$text-white : #ffffff;
$text-subnavigation-menu  : #677489;
$text-recent-review-name : #333333;

$text-nav : #2B3342;
$bg-nav : #F5F9FB;
$bg-white:#ffffff;
$bg-darkBlue : #2B3342;
$bg-light-white : #F5F5F5;
$bg-gray : #f4f4f4;

$border-quote : #F4F4F4;
$text-primary : #2B3342;
$border-technology : #D3D3D3;

$text-family : 'Mulish', sans-serif;
$main-fontFamily : 'Mulish', sans-serif;
$footer-title-fontFamily : 'Inter', sans-serif;
$text-family-Montserrat : 'Montserrat', sans-serif;
$font-family-poppins :  'Poppins', sans-serif;

$border-header : #858384;
$border-white:#ffffff;
$border-subNavigation : #DDDDDD;
$border-contactform-mobile : #F3F3F3;
$border-svg : #CBCBCB;



@mixin dFlexDesktop() {
    display : flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;
}

@mixin dFlexMobile() {
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
}

@mixin button(){
    border:none;
    outline:none;
    background-color: transparent;
}

/***animation linear******/
@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}

@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}
@mixin linear-animation-mixin($name){
    @keyframes #{$name} {
        0%{
            bottom:0px;
      }
      17%{
            bottom:-70px;
      }
      34%{
            bottom:-140px;
      }
      68%{
          bottom: -210px;
      }
      85%{
          bottom: -280px;
      }
      100%{
          bottom:-350px
      }
    }
}




$tablet-width : 768px;
$desktop-width:1024px;

@mixin table {
    @media (min-width : #{$tablet-width} ) and (max-width : #{$desktop-width - 1px} ) {
        @content;
    }
}


@mixin desktop{
    @media (min-width : #{$desktop-width}){
        @content;
    }
}


