@import "../../styles/variable";

.our-services{
    // margin-top: -2.5em;
    // padding-top: 3em;
    // display: none;
    width: 100%;
    position: relative;
    background-color: $bg-white;
    // padding-bottom: 3em;
    font-family: $text-family;
    


    .roundedTop{
        height: 1px;
        background-color: $bg-white;
        position: relative;

        &:before{
            box-sizing: border-box;
            width: 100%;
            padding: 1em;
            content: "";
            z-index: 2;
            position: absolute;    
            background-color: $bg-white;
            border-radius: 3em 3em 0 0;
            margin-top: -0.5em;
        }

    }

    @media only screen and (min-width : 1080px) {
        
        .roundedTop{
            height: 1px;
            background-color: $bg-white;

            &:before{
                box-sizing: border-box;
                width: 100%;
                padding: 1.5em;
                content: "";
                z-index: 2;
                position: absolute;    
                background-color: $bg-white;
                border-radius: 3em 3em 0 0;
                margin-top: -1.5em;
                
            }

        }

    }
    .container{
        text-align: center;
        padding-top: 1.5em;

        .subtitle{
            font-size: 0.625rem;
            color : $golden;
            font-family: $text-family;
            font-weight: 700;
            line-height: 1.8em;
            margin: 0;
            text-transform: uppercase;
        }
        .title{
            font-size: 1.25rem;
            color:$text-primary;
            margin: 0;
        }


        @include desktop(){
            .subtitle{
                font-size: 1.125rem;
                color : $golden;
                font-family: $text-family;
                font-weight: 700;
                line-height: 1.8em;
                margin: 0;
                text-transform: uppercase;
            }
            .title{
                font-size: 2.5rem;
                color:$text-primary;
                margin-top: 0;
                margin-bottom: 1em;
            }  
        }

        

        .content{
            display: none;
            margin-top: 4em;

            .wrapper-hexagonal{
                .hexagonal-side{
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    box-sizing: border-box;
                    margin-top: -20px;
                    gap: 20px;
                   
                    
                           
                    
                  
                        svg{
                            width: 118.49px;
                            height: 137px;
                        }
                    


                }

                @media all and (max-width:1450px) {
                    .hexagonal-side{
                        margin-top: -28px;
                        gap: 10px;

                    }
                }


            }

            .description{
                max-width: 420px;
                text-align: left;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                /* or 144% */
                align-items: center;
                letter-spacing: 0.25px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color:$text-bg-white ;
                
                h4{
                    text-align: left;
                    width: 100%;
                    color:$text-primary;
                }
                @include desktop(){
                    p{
                        font-size: 1rem;
                    }
                }
            }
        }

        @media all and (min-width:1024px) {
            .content{
                display: grid;
                grid-template-columns: minmax(320px,500px) 1fr;
            }
            .title{
                font-size: 2.5rem;
            }
        }

    }



    .content_mobile{
        margin-top: 4em;
    }
}

@media screen and (max-width : 650px){
    .our-services{
        .container{
            h2{
                margin : 0;
            }
        }
    }
}