@import '../../styles/variable';


.footer-wrapper{
    max-width : 1850px;
    margin : 0 auto;
    position: relative;
}

footer{
    font-family: $main-fontFamily;
    background-image : url(Footer-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

//footer a{
//    a styles for footer here
// }

.footer-container{
    background-color : $bg-darkBlue;
    color : $text-white;
}

.footer-flex-item1{
    @include dFlexDesktop;
    justify-content : space-between;
    align-items : flex-start;
    padding : 50px 8vw 50px 8vw;
}

.footer-flex-item1 a{
    text-decoration : none;
    color : $text-white;
}

.text-underline{
    border-top : 2px solid $text-white;
    max-width: 65px;
    margin-top : -10px;
}

.footer-flex-item2{
    @include dFlexDesktop;
    justify-content : space-between;
    border-top : 1px solid $text-white;
    padding : 8px 8vw 8px 8vw;
}

.footer-flex-item1 ul{
    padding-left : 25px;
}

.footer-flex-item1 ul li{
    font-size : 14px;
    line-height: 25px;
    font-weight : 600;
    // transition : 0.2s;
}

#ul-style-remove{
    list-style-type: none;
    padding-left : 0;
}

#ul-style-remove li{
    @include dFlexDesktop;
    justify-content : flex-start;
    align-items : flex-start;
    gap : 10px;
    margin-bottom : 10px;
}

.flex-item1{
    @include dFlexDesktop;
    flex-direction : column;
    gap : 20px;
    padding-top : 60px;
}

.social-img-container{
    margin-top : 20px;
}

.flex-item1 h3, .flex-item1 p{
    margin : 0;
    text-align: center;
}

.flex-item1 h3{
    font-family: $footer-title-fontFamily;
    font-weight : 400;
    font-size : 24px;
    color : $logo-text-gray;
}

.flex-item1 p{
    font-size : 18px;
    letter-spacing : 1.5px;
}

.footer-flex-item2 p{
    font-size : 14px;
    font-weight : 400;
}

.social-img-container{
    display : flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;
    gap : 10px;
}

#text-infine-golden{
    color : $golden;
}

.main-links{
    @include dFlexDesktop;
    justify-content : space-between;
    gap : 15px;
}

.main-links{
    a{
        position : relative;
    }

    a::after{
    content : '';
    position : absolute;
    width : 100%;
    transform : scaleX(0);
    height : 1px;
    bottom : 0px;
    left : 0;
    background-color : $bg-white;
    transform-origin : bottom right;
    transition : transform 0.3s ease-out;
    top : 20px;
    }

    a:hover::after{
        transform : scaleX(1);
        transform-origin : bottom left;
    }
}

.main-links a{
    text-decoration : none;
    color : $text-white;
    white-space: nowrap;
}

 @media screen and (max-width : 1100px){
    .footer-flex-item1{
        @include dFlexMobile();
        padding : 0;
        align-items : center;
    }

    .footer-flex-item2{
        @include dFlexMobile();
        padding : 0;
    }

    .flex-item3{
        margin-left : -75px;
    }

    .footer-flex-item1 ul{
        padding : 0px;
    }

    .flex-item4{
        margin-left : 100px;
    }

    .main-links{
        display : none;
    }

 } 

 @media screen and (max-width : 440px) and (min-width : 300px){
    .flex-item4{
        margin-left : 18%;
    }

    .footer-flex-item2{
        text-align : center;
    }
 }

 @media screen and (max-width : 450px){
    .flex-item2, .flex-item3{
        align-self : flex-start;
        padding-left : 40px;
    }

    .flex-item3, .flex-item4{
        margin-left : 0;
    }

    .flex-item4{
        align-self : flex-start;
        padding-left: 30px;
    }

    .flex-item4 br{
        display : none;
    }
 }



 .scrolltop{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .container{
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        max-width: 100%;

        .scrolltoptopContainer{
            transform: rotate(-90deg);
            position: relative;
            top: -30px;
        }

    }
   
 }