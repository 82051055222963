@import "../../styles/variable";

.generalOurServices_container{
    font-family: $text-family;

    .container{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 3em 0;
        background-color: white;
        // overflow: hidden;


        .left_GeneralOurServices{
            flex: 1;
            
            .subtitle{
                font-size: 0.625rem;
                color : $golden;
                font-family: $text-family;
                font-weight: 700;
                line-height: 1.8em;
                margin: 0;
                text-transform: uppercase;
                text-align: center;
            }
            .title{
                font-size: 1.25rem;
                color:$text-primary;
                font-family: $text-family;
                text-align: center;
                margin: 0;
                font-weight: 700;
                margin-bottom: 1em;

            }

            .description{
                font-size: 1rem;
                font-weight: 300;
                line-height: 24px;
                color:$text-primary;
                font-family: $text-family;
                margin: 1em 0;
                padding: 10px;
            }

            .button{
                background: $golden;
                color:$text-white;
                border-radius: 5px;
                padding:0.7em 1.5em;
                text-decoration: none;
                display: block;
                width: fit-content;
                margin: auto;
                margin-top: 1.5em;
                margin-bottom: 1.5em;
            }
        }

        .right_GeneralOurServices{
            flex: 1;
            display: flex;
            width: 100%;
            overflow-x: scroll;
            gap: 10px;
            padding: 1em auto;
            margin: 1em auto;
            margin-bottom: 1.5em;


            .card{
                box-shadow: -5px 0px 10px rgba(167, 168, 169,0.25),
                5px 5px 10px rgba(167, 168, 169,0.25);
                border-radius: 10px;
                background-color: $bg-white;
                padding: 1em;
                color: $text-primary;
                min-width: 300px;
                max-width:360px;
                margin: 10px;

               .top{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .icons{
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title{
                        font-size: 0.875rem ;
                        font-weight: 600;
                        color: $text-primary;
                    }
               }
                .description{
                    font-size: 0.75rem;
                    font-weight: 300;
                    color: $text-primary;
                    margin-top: 1em;
                }
            }

            @media screen and (min-width : 360px) {
                .card{
                    min-width:340px;
                }
            }
            @media screen and (min-width : 400px) {
                .card{
                    min-width:375px;
                }
            }

           
        }
       
        .right_GeneralOurServices::-webkit-scrollbar {
            display: none;
        }

    }

    @include desktop(){
        .container{
            flex-direction: row;

            .left_GeneralOurServices {
                padding: 3em 0;
               
                    .subtitle{
                        font-size: 1.125rem;
                        color : $golden;
                        font-family: $text-family;
                        font-weight: 700;
                        line-height: 1.8em;
                        margin: 0;
                        text-transform: uppercase;
                        text-align: left;
                    
                    }
                    .title{
                        font-size: 2.5rem;
                        color:$text-primary;
                        margin-bottom: 0.5em;
                        font-family: $text-family;
                        text-align: left;
                        margin-top: -7px;
                    }  
                    .button{
                        margin-right: auto ;
                        margin-left: 0;
                        font-weight: 700;
                    }
                    .description{
                        max-width: 550px;
                        padding: 0;
                    }
            
            }
            .right_GeneralOurServices {
                flex-direction: column;
                overflow-y: scroll;
                gap: 10px;
                max-height: 500px;
                
                    .card{
                        max-width: 550px;
            
    
                        .top{
                            
                            .icons{
    
                                .img{
        
                                }
                            }
    
                            .title{
                                font-size: 1.25rem ;
                                font-weight: 600;
                            }
                            
                        }
                        .description{
                            font-size: 1rem;
                            font-weight: 300;
                        }
                       
                    } 
           
            }

        }

    }
}