@import "../../styles/variable";

.Item-Center{
    display: flex;
    justify-content: center;
    margin-top: -1.4em;
    gap: 1em;
}

.description {
    color : $text-bg-gray;


    p{
        font-size: 0.875rem;
    }
}

@media only screen and (min-width : 1024px){
    .mobileHexagonal{
        display: none;
    }
}
