@import '../../styles/variable';

.aboutUs-Container{
    font-family : $main-fontFamily;
}

.aboutUs-section1{
    @include dFlexDesktop;
    align-items : flex-end;
    gap : 40px;
    padding : 75px;
    width : 100%;
    max-width : 1380px;
    margin : 0 auto;
}

.section1-part1 > :first-child{
    color : $golden;
    font-weight : 700;
    letter-spacing: 1px;
}

.section1-part1 h1{
    margin-top : -15px;
    color : $text-heading;
}

.section1-part1 > :nth-child(3){
    color : $bg-darkBlue;
    font-size : 18px;
    font-weight : 400;
}

.section1-part1 > :nth-child(3) span a{
    text-decoration : none;
    color : $golden;
}

.section1-part2{
    display : flex;
    align-items : center;
    align-self: stretch;
}

.section1-part2 img{
    max-width : 562px;
}

.aboutUs-section2{
    text-align : center;
    @include dFlexDesktop;
    padding : 30px;
    background-image : url(Tagline-bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.quote-InFine{
    text-align : right;
    color : $golden;
    font-size : 19px;
}

.quote-InFine span{
    color : $logo-text-gray;
}

.center h1{
    font-size : 40px;
    color : $bg-darkBlue;
    letter-spacing: 1px;
}

.aboutUs-section5{
    padding : 0px 50px 120px 50px;
    margin-top : 50px;  
}

.section5-part1{
    @include dFlexDesktop;
    flex-direction : column;
    border : 2px solid $border-quote;
    border-radius : 20px;
    margin : 0 auto;
    max-width : 1092px;
    height : 300px;
    text-align : center;
}

#quoteIcon{
    margin-right : 10px;
}

.section5-part1 > :first-child{
    margin-top : 150px;
    color : $text-heading;
    font-size : 20px;
    font-weight : 600;
}

.section5-part1 img{
    width : 261px;
    height : 260px;
}

.section5-part2{
    align-self :flex-end;
}

.quote-author{
    @include dFlexDesktop;
    margin-right : -60px;
    margin-top : -30px;
    align-items : flex-start;
    gap : 20px;
}

.quote-author-info > :first-child{
    color : $golden;
    font-size : 19px;
}

.quote-author-info > :last-child{
    font-size : 14px;
    font-weight : 300;
    color : $text-heading;
}

.quote-author-info > :last-child span{
    color : $golden;
}

iframe{
    min-width : 460px;  
}

.aboutUs-section7-container{
    margin : 50px auto;
    padding : 50px 10px 50px 10px !important; 
    border-radius : 50px;
    box-shadow : 0px 141px 200px -80px rgba(25, 58, 75, 0.3);
    background: linear-gradient(to left, #2B3342 30%, transparent 10%);
}

.aboutUs-section7{
    @include dFlexDesktop;
    gap : 50px;
    align-items: stretch;
}

.section7-part1{
    @include dFlexDesktop;
    align-items : flex-start;
    justify-content : space-between;
    flex-direction : column;
    padding : 40px 20px 40px 20px !important;
}

.part1-item1 h1{
    font-weight : 700;
    font-size : 40px;
    color : $text-heading;
}

.part1-item1 span{
    color : $golden;
    margin-right : 10px;
}

.part1-item1 h1{
    margin : 0;
}

.part1-item1 p{
    font-size : 14px;
    font-weight : 400;
    color : $text-heading;
}

.section7-part1 form input, textarea{
    border : 1px solid #D9DEE2;
    width : 100%;
    min-height : 40px;
    margin-top : 6px;
}

form textarea{
    display : block;
    min-height : 140px;
    resize: none;
}

form label{
    color : $text-heading;
    font-weight : 600;
}

.part1-item2{
    width : 100%;
}

form{
    width : 100%;
}

.formElement{
    margin-top : 25px;
}


#asterisk{
    color : red;
}

.part1-item3{
    @include dFlexDesktop;
    justify-content : flex-start;
    gap : 15px;
    min-width : 100%;
    margin-top : 20px;
    font-size : 13px;
    font-weight : 400;
}

.part1-item3 p{
    margin : 0;
    font-size : 14px;
    font-weight : 600;
}

.social-info-1, .social-info-2, .social-info-3{
    @include dFlexDesktop;
    gap : 10px;
}

.social-info-1-text > :first-child, .social-info-2-text > :first-child, .social-info-3-text > :first-child{
    color : $text-heading;
}

.social-info-1-text > :last-child, .social-info-2-text > :last-child, .social-info-3-text > :last-child{
    color : $golden;
}

.social-info-3-text > :last-child a{
    text-decoration : underline;
    color : $golden;
}

.section7-part2{
    align-self : center;
}

@media screen and (max-width : 1032px){

    .aboutUs-section2{
        background-image : url('./bgQuoteMobile.svg');
        background-size : cover;
        background-position : center;
    }

    .quote-author{
        @include dFlexMobile;
        justify-content : flex-start;
        margin-right : 0px;
        gap : 0;
    }

    .section5-part2{
        margin : 0 auto;
        padding : 0px 0px 50px 0px;
    }

    .section5-part1{
        padding-top : 150px;
        min-width : 500px;
        margin : 0 auto;
        border-radius : 10px;
    }

    .section5-part1 > :first-child{
        font-size : 16px;
    }

    .aboutUs-section5{
        padding : 15px 15px 150px 15px;
        min-width : 500px;
        margin : 20px auto;
    }

    .section5-part1 > :first-child{
        margin-top : 100px;
    }
}

@media screen and (max-width : 501px){
    .aboutUs-section5{
        min-width : 100%;
    }

    .section5-part1{
        min-width : 90%;
        padding-top : 150px;
    }
}

@media screen and (max-width : 575px){
    .center h1{
        font-size : 6vw;
    }
}

@media screen and (max-width : 1280px){
    .section1-part1 p #remove{
        display : none;
    }
}

@media screen and (max-width : 1095px){

    .aboutUs-section1{
        padding : 0 10px 0 10px;
        background-image : url('HeroMobileVersion_1.png');
        background-repeat: no-repeat;
        background-position : center;
        background-size: cover;
    }

    .section1-part2{
        display : none;
    }

    .section1-part1 > :first-child, .section1-part1 h1{
        text-align : center;
    }
}

@media screen and (max-width : 450px){

    .aboutUs-section2{
        background-image: url('TaglineBgMobile.svg');
        background-position : left;

    }
}

@media screen and (max-width : 650px){
    .aboutUs-section7-container{
        margin-top: 0;
    }
}


