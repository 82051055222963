@import "../../styles/variable";

.navigationTechnology_Mobile{
    font-family: $text-family;
    color:$text-primary;

    &.container{
        padding: 2em 1em;

        .subtitle{
            font-size: 0.625rem;
            color : $golden;
            font-family: $text-family;
            font-weight: 400;
            line-height: 1.8em;
            margin: 0;
            text-transform: uppercase;
            text-align: center;
        }
        .title{
            font-size: 1.25rem;
            color:$text-primary;
            margin: 0;
            font-weight: 700;
            text-align: center;
        }
    
        .section_Navigation{
            margin-bottom: 1em;
            margin-top: 1.5em;
    
            h4{
                margin: 0;
                margin-bottom: 1em;
                color:$text-primary;
                text-decoration: underline;
            }

            .navigation{
                display: flex;
                flex-direction: row;
                gap: 10px;
                overflow-x: auto;

                .box{
                    min-width: 109px;
                    min-height: 102px;
                    max-width: 109px;
                    max-height: 102px;
                    // border: 1px solid $border-technology ;
                    // box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.1);
                    // background-color: $bg-white;
                    // padding: 13px 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;


                    .icon{
                        // width: ;


                        img{

                        }

                    }

                    img{
                        width: 100%;
                        height: 100%;
                    }

                    .name{
                        font-size:0.75rem ;
                        text-decoration: none;
                        color:$text-primary;
                        white-space: nowrap;
                    }
                }
            }

            .navigation::-webkit-scrollbar{
                display: none;
            }


        }
    }
}

@include desktop(){
    .navigationTechnology_Mobile{
        display: none;
    }
}




.container-technology {
    .ImageDescription-container {
        padding-left: 6vw;
        padding-bottom: 30px;

        .container {
            .left {
                .subtitle {
                    margin: 0;
                }

                h2 {
                    // margin: 0;
                    // // margin-top: -10px;
                }
            }
        }
    }

    .toolstouse {
        .container {
            padding-top: 50px;
        }
    }

    .container-our-procedure {
        padding-top : 50px;
        .aboutUs-section3 {
            padding-top: 0;
        }
    }

    .generalOurServices_container{
        .container{
            .left_GeneralOurServices{
                .title{
                    margin-top: -10px;
                }

                .description{
                    // margin-top : -20px;
                }
            }

            .right_GeneralOurServices{
                max-height : 300px;
                 .card{
                    .top{
                        .title{
                            margin : 0 auto;
                            text-align: center;
                        }
                    }
                 }
            }
        }

    }
}

@media screen and (max-width : 650px) {
    .container-technology{
        .container-our-procedure{
            padding-top : 25px;
        }
    }

}

@media screen and (max-width : 1280px) {

    .container-technology {
        .container-hireusnow {
            display: none;
        }
    }

}

@media screen and (max-width : 450px){
    .container-technology{
        .ImageDescription-container{
            padding-left: 0;
            text-align: center;
        }
    }
}